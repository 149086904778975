    body{
        counter-reset: li;
    }
    .carousel-indicators li{
        //   content: counter(li,decimal);
          text-indent: 0!important;
          margin: 0 8px !important;
          width: 30px!important;
          height: 30px !important;
          border: none !important;
          color: #fff;
          transition: all 0.25s ease;
          transform: rotate(45deg);
          background-color: #585858 !important;
          opacity: unset !important;
          font-weight: 500;
        }
    .carousel-indicators li.active {
          background-color: #009eb2 !important; 
        }
    .carousel-indicators li:before{
      counter-increment: li;
      content: counter(li);
      position: absolute;  
      top: 0;
      left: 0;  
      vertical-align: middle;
      display: inline-block;
      width: 30px!important;
      height: 30px!important;
      transform: rotate(-45deg);
      padding: 3px;
      font-weight: 500;
      color: #ffffff;
      text-indent: 0;
  }
.carousel-indicators {
    bottom: -60px !important;
}
.carousel-inner {
        margin-top: 35px;
    }
.badge-with-text-container{
        padding-top: 6px;
    }
.carousel-control-next, .carousel-control-prev {
        top: -40px;
}
// .mobile-careousel li:before:nth-child(2) {
//     content: n+1;
//   }