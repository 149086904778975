.modal-body{
    font-size: 0.7em;
    max-height: 500px;
    overflow: scroll;
    padding: 36px;
}
iframe{
    border: none;
    width: 100%;
    height: 100%;
}
.modal-header{
    padding: 0!important;
}
.width50 .modal-dialog{
    max-width: 750px;
    margin: auto;
}
.width50 .modal-body{
    padding: 0;
}
.width50 .modal-content{
    height: 450px;
}
@media only screen and (max-width: 600px) and (orientation: portrait){
    .width50 .modal-content{
        height: 228px;
    }
  }
  @media only screen and (max-width: 667px) and (orientation: landscape){
    .width50 .modal-content{
        height: 100vh;
    }
  }
  
