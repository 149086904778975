@media(max-width:768px) {
    .mobile-careousel{
        display: block !important;
    }
    .three-column-grid{
        display: none !important;
    }
}

@media(min-width:768px) {
    .mobile-careousel{
        display: none !important;
    }
    .three-column-grid{
        display: table !important;
    }
}
// @media all and (min-width: 768px) and (min-height: 1024px){
//     .mobile-careousel{
//         display: block !important;
//     }
//     .three-column-grid{
//         display: none !important;
//     }
//   }