.modal-body{
    font-size: 0.7em;
    max-height: 500px;
    overflow: scroll;
    padding: 36px;
}
.width80 .modal-dialog{
    max-width: 80%;
}
@media only screen and (max-width: 600px){
    .width80 .modal-dialog-centered {
      margin: 0;
      padding: 0;
      max-height: 100vh;
      min-height: 100vh;
      align-items: unset;
      width: 100vw;
      max-width: 100vw;
      }
      .width80 .modal-body{
          overflow: auto;
      }
      .width80 .modal.show .modal-dialog{
          max-width: 100%;
      }
  }
  @media only screen and (max-width: 768px){
    .width80 .modal.show .modal-dialog{
        max-width: 100%;
    }
    .width80 .modal-body{
        overflow: auto;
    }
    .width80 .modal-dialog-centered {
        margin: 0;
        padding: 0;
        max-height: 100vh;
        min-height: 100vh;
        align-items: unset;
        max-width: 100vw;
        width: 100vw;
        }
    .width80 .modal-open .modal{
        overflow-y: hidden;
    }
  }