.loading-page{
    background-color: #495057;
    width: 100%;
    height: 100vh;
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 150px;
    font-size: 12px;
    text-align: center;
    transform: translate(-50%,-50%);
}

.loader span{
    display: inline-block;
    font-size: 24px;
}

.image {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }