@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

@font-face {
    font-family: Mallory;
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Mallory-Book.woff) format("woff")
}

@font-face {
    font-family: Mallory;
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/Mallory-Bold.woff) format("woff")
}

@font-face {
    font-family: Mallory;
    font-style: normal;
    font-weight: 100;
    src: url(../fonts/Mallory-Light.woff) format("woff")
}

@font-face {
    font-family: Mallory;
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/Mallory-Medium.woff) format("woff")
}

.primary-button {
    font-family: Mallory, Arial, Helvetica, sans-serif;
    font-size: 1em;
    background-color: #187B8B;
    border: 2px solid #187B8B;
    color: #fff;
    text-decoration: none;
    padding: 10px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    transition: opacity .3s;
    opacity: 1;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    box-sizing: border-box
}

@media (min-width:1024px) {
    .primary-button {
        padding: 10px 40px;
        width: auto
    }
}

@media (min-width:1024px) {
    .primary-button:hover {
        background-color: #00717f;
        border: 2px solid #00717f;
        color: #FFF;
        text-decoration: none;
    }
}
.privacy-terms.desktop{
    margin-bottom: 12px;
}

.primary-button[disabled] {
    border-color: #ccc;
    background-color: #ccc
}

.primary-button.loading {
    color: transparent
}

.primary-button.loading:after {
    content: "";
    background-image: url(../images/2c1229a23bdb3d3853ca2d893e80476b.gif);
    background-repeat: no-repeat;
    background-size: cover;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.primary-button+.primary-button {
    margin-top: 20px
}

@media (min-width:1024px) {
    .primary-button+.primary-button {
        margin-top: 0;
        margin-left: 20px
    }
}

.primary-button.primary-button-tr {
    background-color: transparent;
    border: 2px solid #fff
}

.button-icon {
    text-indent: 26px
}

@media (min-width:1024px) {
    .button-icon {
        padding-left: 65px;
        text-indent: 0
    }
}

.button-icon .button-icon-play {
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    margin-left: -25px
}

.button-icon .button-icon-play:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 8px;
    border-color: transparent transparent #585858;
    position: absolute;
    left: 2px;
    transform: rotate(90deg) translate(-50%, -50%);
    top: 50%;
    margin-top: 1px
}
.price-description-container .price-description-price .price-description-icon-toggle{
    transform: rotate(45deg) translatey(0%) translateX(-90%);
}
.box-diamond {
    text-align: center;
    position: relative;
    line-height: 2.4;
    margin: 0 10px
}

.box-diamond,
.box-diamond:after {
    width: 40px;
    height: 40px;
    display: inline-block
}

.box-diamond:after {
    content: "";
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    background-color: #fff;
    transform: rotate(45deg)
}

.close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    /* border: 2px solid #187B8B; */
    height: 30px;
    width: 30px;
    z-index: 1;
    outline: solid 10px #fff;
    background-color: #ffffff;
}
.close-btn:focus{
    background-color: #b2f6ff;
}

.close-btn:after,
.close-btn:before {
    content: "";
    position: absolute;
    height: 2px;
    margin-top: -2px;
    width: 15px;
    top: 14px;
    left: 6px;
    background-color: #187B8B
}

.close-btn:before {
    transform: rotate(45deg)
}

.close-btn:after {
    transform: rotate(-45deg)
}

.clearfix:after {
    content: "";
    display: table;
    clear: both
}

@media (min-width:1024px) {
    .hide-desktop {
        display: none!important
    }
}

.hide-mobile {
    display: none!important
}

@media (min-width:1024px) {
    .hide-mobile {
        display: inline-block!important
    }
}

.heading-1 {
    font-size: 3em;
    font-weight: 100
}

.heading-1,
.heading-2 {
    font-family: Mallory, Arial, Helvetica, sans-serif
}

.heading-2 {
    font-size: 1.625em;
    font-weight: 300
}

@media (min-width:1024px) {
    .heading-2 {
        font-size: 2.25em
    }
}

.heading-3 {
    font-size: 1.25em;
    font-weight: 500
}

.heading-3,
.heading-4 {
    font-family: Mallory, Arial, Helvetica, sans-serif
}

.heading-4 {
    font-size: 1em;
    font-weight: 700
}

.heading-5,
.heading-6 {
    font-size: .875em;
    line-height: 1.5
}

.common-text,
.heading-5,
.heading-6 {
    font-family: Mallory, Arial, Helvetica, sans-serif;
    font-weight: 300
}

.common-text {
    font-size: 1em
}

@media (min-width:1024px) {
    .common-text {
        font-size: 1.125em
    }
}

.common-text-light {
    font-size: 1em;
    font-weight: 100
}

.common-text-light,
.common-text-line-height {
    font-family: Mallory, Arial, Helvetica, sans-serif
}

.common-text-line-height {
    font-size: .8em;
    font-weight: 300;
    line-height: 1.8
}

@media (min-width:1024px) {
    .common-text-line-height {
        font-size: 1em;
        line-height: 1.6
    }
}

.homepage .faqs .heading-4 {
    font-weight: 500
}

.formpage .heading-2 {
    font-size: 2.5em
}

body {
    font-family: Mallory, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    background: #f5f5f2;
    position: relative;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected {
    background-color: #fff!important
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px #fff inset
}

a {
    color: inherit;
    text-decoration: none
}

p a {
    text-decoration: underline;
    font-weight: 700
}

img {
    max-width: 100%
}

.locked-down {
    position: fixed;
    overflow-y: hidden
}

@media (min-width:768px) {
    .locked-down {
        position: relative
    }
}

.ada {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    display: block;
    opacity: 0
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.page-container {
    max-width: 1500px;
    margin: auto;
    position: relative;
    height: 100%
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.header-nav-container {
    height: 50px
}

@media (min-width:1024px) {
    .header-nav-container {
        height: 130px
    }
}

.header-nav-container .header-nav-container-inner {
    max-width: 1500px;
    width: 1500px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 2
}

@media (max-width:1500px) {
    .header-nav-container .header-nav-container-inner {
        width: 100%
    }
}

@media (min-width:1024px) {
    .header-nav-container .header-nav-container-inner:before {
        content: "";
        height: 60px;
        background-color: #187B8B;
        display: block
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.blade-container>div,
.blade-container>header {
    padding: 30px 30px 80px;
    margin: auto;
    text-align: center
}

@media (min-width:1024px) {
    .blade-container>div,
    .blade-container>header {
        padding: 60px 80px 80px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.nav {
    width: 100%;
    height: 50px;
    background-color: #fff
}

@media (min-width:1024px) {
    .nav {
        width: auto;
        margin: 10px auto;
        padding: 0;
        height: 50px;
        text-align: center
    }
}

.nav .nav-logo {
    display: inline-block;
    vertical-align: top;
    padding: 10px 0;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.nav .nav-logo img {
    max-height: 35px
}

@media (min-width:1024px) {
    .nav .nav-logo {
        -webkit-transform: none;
        transform: none;
        left: 40px
    }
}

.nav .nav-item-slide {
    position: fixed;
    top: 0;
    left: 0;
    right: 20%;
    bottom: 0;
    pointer-events: none;
    z-index: 100
}

@media (min-width:1024px) {
    .nav .nav-item-slide {
        position: static;
        pointer-events: all;
        display: inline-block;
        vertical-align: top
    }
}

.nav.active:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

@media (min-width:1024px) {
    .nav.active:after {
        display: none
    }
}

.nav.active .nav-item-slide {
    pointer-events: all
}

.nav.active .nav-item-wrapper,
.nav.active .nav-pattern {
    left: 0
}

.nav.active .nav-hamburger-box {
    opacity: 0
}

.nav .nav-slide-close {
    position: absolute;
    top: 10px;
    right: 10px
}

@media (min-width:1024px) {
    .nav .nav-slide-close {
        display: none
    }
}

.nav .nav-hamburger-box {
    cursor: pointer;
    position: fixed;
    display: inline-block;
    width: 50px;
    left: 0;
    top: -5px;
    height: 50px;
    opacity: 1;
    -webkit-transition: all .15s;
    transition: all .15s
}

@media (min-width:1024px) {
    .nav .nav-hamburger-box {
        display: none
    }
}

.nav .nav-hamburger-box:after {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    left: 20px;
    top: 5px
}

.nav .nav-hamburger-inner,
.nav .nav-hamburger-inner:after,
.nav .nav-hamburger-inner:before {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    background-color: #187B8B
}

.nav .nav-hamburger-inner {
    top: 23px;
    left: 20px;
    margin-top: -2px
}

.nav .nav-hamburger-inner:before {
    content: "";
    top: 8px;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform
}

.nav .nav-hamburger-inner:after {
    content: "";
    top: 16px;
    bottom: -10px
}

.nav .nav-discaimer {
    padding: 12px 30px
}

.nav .nav-item-wrapper {
    position: absolute;
    width: 100%;
    left: -100vw;
    -webkit-transition: all .2s;
    transition: all .2s;
    padding: 80px 0 30px;
    box-sizing: border-box;
    top: 0;
    background-color: #fff;
    z-index: 1
}

@media (min-width:1024px) {
    .nav .nav-item-wrapper {
        position: static;
        padding: 0;
        box-shadow: none;
        background-color: #fff;
        background-image: none
    }
}

.nav .nav-pattern {
    -webkit-transition: all .2s;
    transition: all .2s;
    background-image: url(../images/610cf8c02f066ba66d787bfef4de59b9.png);
    background-size: 69%;
    background-color: #fff;
    left: -100vw;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%
}

@media (min-width:1024px) {
    .nav .nav-pattern {
        display: none
    }
}

.nav .nav-item {
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    padding: 12px 30px;
    text-transform: uppercase;
    color: #000
}

@media (min-width:1024px) {
    .nav .nav-item {
        margin-right: 45px;
        padding: 12px 0 0;
        display: inline-block;
        margin-top: 20px
    }
}

.nav .nav-item:before {
    content: "";
    height: 1px;
    width: 30px;
    bottom: 5px;
    background-color: #000;
    position: absolute;
    -webkit-transition: all .2s;
    transition: all .2s
}

@media (min-width:1024px) {
    .nav .nav-item:before {
        width: 100%;
        bottom: 4px
    }
}

.nav .nav-item.active,
.nav .nav-item:hover {
    color: #187B8B
}

.nav .nav-item.active:before,
.nav .nav-item:hover:before {
    background-color: #187B8B
}

.nav .nav-button {
    width: calc(100% - 60px);
    position: absolute;
    left: 30px;
    bottom: 30px;
    text-transform: uppercase
}

@media (min-width:1024px) {
    .nav .nav-button {
        position: absolute;
        right: 20px;
        width: auto;
        left: auto;
        bottom: auto;
        padding: 15px 40px 12px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.opacity-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 100
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.header {
    background-image: url(../images/786a958c03cee290753b1d7d4b00d1b5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: auto;
    padding-bottom: 5px!important;
    padding-top: 0!important
}

.header .opacity-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: .3;
    display: none
}

.header .header-logo {
    margin-top: 50px;
    width: 300px;
    position: relative
}

.header .header-title {
    position: relative
}

.header .primary-button {
    display: block;
    width: auto;
    min-width: 150px;
    margin-bottom: 30px
}

.header .header-logo-strip-container {
    position: absolute;
    height: 150px;
    background: #585858;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow-x: auto
}

@media (min-width:768px) {
    .header {
        background-image: url(../images/306542fdf5e5ac30f4550151a80a3b5f.jpg);
        height: auto
    }
    .header .opacity-overlay {
        display: block
    }
    .header .header-logo-strip-container {
        position: absolute;
        height: 150px;
        background: rgba(0, 0, 0, .3);
        bottom: 0;
        left: 0;
        right: 0
    }
    .header .primary-button {
        display: inline-block;
        width: auto;
        margin-left: 20px;
        width: 190px
    }
}

@media (min-width:1400px) {
    .header .opacity-overlay {
        display: block
    }
    .header .header-logo-strip-container {
        position: absolute;
        height: 150px;
        background: rgba(0, 0, 0, .3);
        bottom: 0;
        left: 0;
        right: 0
    }
    .header .primary-button {
        display: inline-block;
        width: auto
    }
}

@media (min-width:1024px) {
    .header {
        padding: 60px 0 100px
    }
    .header .opacity-overlay {
        display: block
    }
    .header .header-logo-strip-container {
        position: absolute;
        height: 150px;
        background: rgba(0, 0, 0, .3);
        bottom: 0;
        left: 0;
        right: 0
    }
    .header .primary-button {
        display: inline-block;
        width: auto
    }
}

.header .header-title {
    margin: 0 auto 20px;
    text-align: center;
    color: #fff;
    font-size: 1.75em;
    margin-top: 10px;
    margin-bottom: 25px
}

@media (min-width:1024px) {
    .header .header-title {
        font-size: 2.425em;
        width: 600px
    }
}

.header .header-description {
    margin-bottom: 50px;
    font-size: 14px
}

.header .header-learn-more-bottom {
    position: absolute;
    left: 50%;
    bottom: -25px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1
}

.header .header-learn-more-bottom .header-learn-more-bottom-text {
    display: block;
    padding-bottom: 15px;
    color: #e0b67f;
    text-transform: uppercase
}

.header .header-arrow-down {
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2
}

.header .text-with-icons {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px
}

.header .text-with-icons .text-with-icons-title {
    margin-top: 0
}

.header .header-disclaimer {
    color: #fff;
    font-size: .75em;
    margin: auto;
    max-width: 830px
}

.header .header-disclaimer.header-disclaimer {
    margin-top: 10px
}

.header .generic-carousel .arrow.left:after,
.header .generic-carousel .arrow.right:after {
    color: #fff
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.logo-strip {
    position: relative;
    height: 100%
}

.logo-strip .company-logo {
    vertical-align: middle;
    display: inline-block;
    height: 100%
}

.logo-strip:first-child {
    margin-left: 0
}

@media (min-width:0) and (max-width:767px) {
    .logo-strip {
        width: 160%
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.text-with-icons {
    margin-bottom: 30px
}

.text-with-icons .text-with-icons-title {
    color: #fff;
    font-size: .8125em
}

@media (min-width:1024px) {
    .text-with-icons .text-with-icons-title {
        font-size: 1.65em;
        margin-bottom: 15px
    }
}

@media (min-width:1400px) {
    .text-with-icons .text-with-icons-title {
        font-size: 2.35em
    }
}

@media (min-width:1024px) {
    .text-with-icons .text-with-icons-img {
        display: inline-block;
        width: 700px
    }
}

.text-with-icons .text-with-icons-disclaimer {
    color: #fff;
    font-size: .5625em
}

@media (min-width:1024px) {
    .text-with-icons .text-with-icons-disclaimer {
        font-size: .75em
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.how-it-works-container {
    position: relative
}

.how-it-works-container.how-it-works-form {
    background-color: #fff
}

.how-it-works-container.how-it-works-form .badge-with-text-copy {
    color: #187B8B
}

.how-it-works-container.how-it-works-header {
    margin-bottom: 30px;
}

/* .how-it-works-container.how-it-works-header .badge-with-text-copy {
    color: #fff
} */

.how-it-works-container .how-it-works-title {
    color: #187B8B;
    font-size: 2.625em;
    font-weight: 500;
    margin: 0
}

.how-it-works-container .badge-with-text-copy {
    max-width: 280px;
    margin: 20px auto 0
}

@media (min-width:768px) {
    .how-it-works-container .three-column-grid .three-column-cell {
        padding: 30px 20px 0;
        box-sizing: border-box
    }
}



@media (max-width:736px) {
     .how-it-works-container.how-it-works-header {
        margin-bottom: 80px;
    } 
    /* .mobile-careousel{
        height: 200px;
    }  */
    .carousel-inner{
        height: 198px;
    }
}


@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.badge-with-text-container .badge-with-text-mark,
.tagWithNumber {
    line-height: 1.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    color: #ffffff;
    font-weight: 400
}

.badge-with-text-container .badge-with-text-mark:before,
.tagWithNumber:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #187B8B;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    z-index: -1;
    display: block
}

@media (min-width:1024px) {
    .fullWidth {
        padding-left: 0!important;
        padding-right: 0!important
    }
}

.mobileFullWidth {
    padding-left: 0!important;
    padding-right: 0!important
}

.badge-with-text-container {
    text-align: center
}

.badge-with-text-container .badge-with-text-icon {
    width: 50px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.badge-with-text-container .badge-with-text-icon-wrapper {
    margin: auto;
    position: relative;
    background-color: #fff;
    border: 1px solid #6c757d;
    border-radius: 50%;
    width: 102px;
    height: 102px;
    z-index: 1
}

.badge-with-text-container .badge-with-text-copy {
    font-weight: 500
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.blade-container>.three-column-grid {
    text-align: left
}

.three-column-grid {
    box-sizing: border-box;
    display: table;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 71%;
}

.three-column-grid .three-column-cell {
    width: 100%
}

@media (min-width:768px) {
    .three-column-grid .three-column-cell {
        padding: 30px 0;
        width: 33%;
        display: inline-block;
        vertical-align: top
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.generic-carousel .counter .counter-item,
.tagWithNumber {
    line-height: 1.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    color: #fff;
    font-weight: 400
}

.generic-carousel .counter .counter-item:before,
.tagWithNumber:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #187B8B;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    z-index: -1;
    display: block
}

@media (min-width:1024px) {
    .fullWidth {
        padding-left: 0!important;
        padding-right: 0!important
    }
}

.mobileFullWidth {
    padding-left: 0!important;
    padding-right: 0!important
}

.generic-carousel {
    position: relative;
    overflow-x: hidden;
    margin-top: 20px;
    padding-top: 10px
}

.generic-carousel .arrow {
    height: calc(100% - 110px);
    width: 40px;
    position: absolute;
    cursor: pointer;
    z-index: 20;
    top: 30px
}

.generic-carousel .arrow.left {
    left: 0
}

.generic-carousel .arrow.left:after {
    content: "<"
}

.generic-carousel .arrow.right {
    right: 0
}

.generic-carousel .arrow.right:after {
    content: ">"
}

.generic-carousel .arrow:after {
    font-weight: 700;
    position: absolute;
    top: 25%
}

.generic-carousel .arrow.disabled {
    opacity: .5
}

.generic-carousel .carousel-container {
    position: absolute;
    left: 0;
    -webkit-transition: left .2s linear;
    transition: left .2s linear
}

.generic-carousel .carousel-item {
    box-sizing: border-box;
    float: left;
    padding-left: 40px;
    padding-right: 40px
}

.generic-carousel .counter {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0
}

.generic-carousel .counter .counter-item {
    display: inline-block;
    position: relative;
    margin: 0 8px;
    -webkit-transform: scale(.9);
    transform: scale(.9)
}

.generic-carousel .counter .counter-item.inactive:before {
    background-color: #585858
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.faqs {
    position: relative;
    padding-top: 0!important
}

.faqs:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #6c757d;
    display: block;
    margin: 0 auto 60px
}

.faqs .faqs-wrapper {
    overflow: hidden
}

.faqs .faqs-wrapper>div {
    display: inline-block;
    vertical-align: top
}

@media (max-width:768px) {
    .faqs .faqs-wrapper>div {
        display: block;
        width: 100%!important;
        padding: 0!important
    }
}

.faqs .faqs-info {
    text-align: left
}

.faqs .faqs-info .faqs-title {
    margin-top: 0;
    margin-bottom: 0
}

@media (min-width:0) and (max-width:768px) {
    .faqs .faqs-info {
        text-align: center
    }
}

.faqs .faqs-accordion-container {
    text-align: left
}

.faqs .faqs-text-button {
    float: right;
    padding-bottom: 50px
}

@media (min-width:1400px) {
    .faqs .faqs-text-button {
        text-align: right;
        margin-top: 30px
    }
}

@media (max-width:1024px) {
    .faqs .faqs-text-button {
        float: none;
        padding-bottom: 10px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.accordion-item {
    position: relative;
    border-bottom: 1px solid #f2f2ef
}

.accordion-item .heading-4,
.accordion-item p {
    line-height: 1.6;
    font-size: .85em;
    color: #373e3e
}

.accordion-item p {
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 22px
}

@media (min-width:1024px) {
    .accordion-item .heading-4.accordion-item-trigger {
        font-size: 1em;
        color: #000;
        padding-top: 35px
    }
    .accordion-item p {
        margin-bottom: 30px;
        margin-top: 0
    }
}

.accordion-item .accordion-item-content {
    max-height: 0;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    overflow: hidden;
    color: #585858;
    border-bottom: 1px solid #858585
}

.accordion-item .accordion-item-content p {
    padding: 0
}

.accordion-item .accordion-item-trigger {
    padding: 20px 0 10px;
    cursor: pointer;
    margin: 0;
    position: relative
}

@media (min-width:1024px) {
    .accordion-item .accordion-item-trigger {
        padding-right: 0
    }
}

.accordion-item .accordion-icon {
    color: #187B8B;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 10px;
    display: block;
    position: relative;
    margin-left: 3px;
    cursor: pointer
}

.accordion-item .accordion-icon:before {
    content: "";
    background-color: #187B8B;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-right: 7px;
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 1px
}

.accordion-item .accordion-icon:after {
    content: "+";
    color: #fff;
    position: absolute;
    left: 3px;
    top: -1px
}

.accordion-item.active .accordion-item-content {
    max-height: 700px
}

.accordion-item.active .accordion-icon:before {
    -webkit-transform: rotate(0);
    transform: rotate(0)
}

.accordion-item.active .accordion-icon:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.text-with-button {
    padding-top: 20px
}

.text-with-button .link {
    color: #187B8B
}

.text-with-button .button {
    margin-top: 20px;
    display: block
}

@media (min-width:1024px) {
    .text-with-button .button {
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
        margin-left: 20px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.wrapper-footer {
    border-top: 1px solid #6c757d;
    background-color: #187B8B;
    color: #fff;
    border-top: 6px solid #e0b67f;
    margin-top: -20px
}

.wrapper-footer a {
    text-decoration: underline;
    font-weight: 700
}

.wrapper-footer .modal-link {
    font-weight: 700
}

.wrapper-footer .footer-image {
    display: none
}

.wrapper-footer .footer {
    font-size: 12px;
    padding: 20px 0;
    max-width: 1200px
}

.wrapper-footer .footer-text {
    padding-top: 32px;
    padding-bottom: 5px;
    text-align: left;
    line-height: 1.2;
}

.wrapper-footer .footer {
    padding-bottom: 121px
}

.wrapper-footer .equal-housing-logo {
    height: 48px;
    overflow: hidden;
    display: block;
    text-align: left;
    position: relative
}

.wrapper-footer .equal-housing-logo .svg-housing {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    padding-right: 15px
}

.wrapper-footer .equal-housing-logo .svg-housing>svg {
    height: 100%
}

@media (min-width:1024px) {
    .wrapper-footer .equal-housing-logo {
        display: inline-block;
        padding-bottom: 0;
        vertical-align: middle;
        padding-right: 40px
    }
}

.wrapper-footer .logos {
    background-color: hsla(0, 0%, 100%, .1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    height: 31px;
    box-sizing: content-box;
    text-align: center;
    display: block
}

.wrapper-footer .privacy-terms .modal-link {
    padding: 0 20px;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width:320px){
    .wrapper-footer .privacy-terms .modal-link {
        padding-right: 10px;
        padding-left: 0px;
    }
}

.wrapper-footer .privacy-terms.desktop {
    display: none
}

.wrapper-footer .privacy-terms.mobile {
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px
}

@media (min-width:768px) {
    .wrapper-footer {
        position: relative
    }
    .wrapper-footer .social {
        height: 30px;
        margin-top: 64px
    }
    .wrapper-footer .footer {
        padding-bottom: 129px
    }
    .wrapper-footer .main-logo {
        width: 460px;
        padding-top: 37px;
        margin-left: auto;
        margin-right: auto
    }
    .wrapper-footer .logos {
        height: 130px;
        box-sizing: content-box;
        display: block;
        padding: 0;
        background-color: transparent
    }
    .wrapper-footer .logos .brands-row {
        height: 70px;
        display: block;
        padding: 30px;
        background-color: hsla(0, 0%, 100%, .1)
    }
    .wrapper-footer .logos .brands-row svg {
        max-height: 100%
    }
    .wrapper-footer .equal-housing-logo {
        padding-bottom: 15px
    }
    .wrapper-footer .equal-housing-logo.housing-logo-right {
        text-align: right;
        float: right;
        padding: 0 25px;
    }
    .wrapper-footer .privacy-terms.mobile {
        display: none
    }
    .wrapper-footer .privacy-terms.desktop {
        display: inline-block;
        vertical-align: middle
    }
    .wrapper-footer .privacy-terms.desktop .modal-link:first-of-type {
        padding-left: 0;
        cursor: pointer;
    }
    .wrapper-footer .privacy-terms.desktop .modal-links {
        padding: 0 30px
    }
    .wrapper-footer .footer-image {
        display: block;
        position: absolute;
        top: -328px;
        left: 0
    }
}

.homepage .wrapper-footer .equal-housing-logo {
    display: block;
    padding-right: 0
}

.formpage .footer {
    padding-bottom: 31px
}

.formpage .equal-housing-logo {
    display: inline-block;
    vertical-align: middle
}

.formpage .svg-housing {
    max-width: 50px
}

.formpage .privacy-terms.mobile {
    display: none
}

.formpage .privacy-terms.desktop {
    display: inline-block;
    vertical-align: middle
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.wrapper .wrapper-inner {
    margin: 0 30px;
}

@media (min-width:1024px) {
    .wrapper .wrapper-inner {
        max-width: 800px;
        margin: auto
    }
}

@media (min-width:1400px) {
    .wrapper .wrapper-inner {
        max-width: 1200px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.footer-bottom-links {
    background-color: #222;
    display: none
}

@media (min-width:1024px) {
    .footer-bottom-links {
        display: block;
        position: relative;
        padding: 70px 70px 30px;
        text-align: center
    }
    .footer-bottom-links .back-to-top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: hsla(0, 0%, 100%, .1);
        height: 50px;
        border: none;
        color: #fff;
        width: 100%;
        font-weight: 700
    }
    .footer-bottom-links .footer-bottom-col {
        display: inline-block;
        height: 100%;
        vertical-align: top;
        text-align: left;
        padding: 0 25px
    }
    .footer-bottom-links .footer-bottom-col a {
        display: block;
        padding-bottom: 10px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.modal-container {
    position: absolute;
    z-index: 4;
    background-color: #fff;
    padding: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    box-sizing: border-box;
    -webkit-animation: .5s forwards slideUpMobile;
    animation: .5s forwards slideUpMobile;
    overflow-y: auto;
    overflow-x: hidden
}

@media (min-width:1024px) {
    .modal-container {
        -webkit-animation: .5s forwards slideUp;
        animation: .5s forwards slideUp;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        bottom: auto
    }
}

.modal-container.full-width {
    width: 100%
}

.modal-container.full-height-m {
    height: 100%;
    overflow-y: auto
}

@media (min-width:1024px) {
    .modal-container.full-height-m {
        height: auto;
        overflow-y: initial
    }
}

.modal-container.content-height-m {
    bottom: auto;
    -webkit-animation: .5s forwards slideUpCenterMobile;
    animation: .5s forwards slideUpCenterMobile
}

@media (min-width:1024px) {
    .modal-container.content-height-m {
        -webkit-animation: .5s forwards slideUp;
        animation: .5s forwards slideUp
    }
}

.modal-container.hidding {
    -webkit-animation: .5s forwards slideDown;
    animation: .5s forwards slideDown
}

@media (min-width:1024px) {
    .modal-container {
        padding: 30px
    }
}

.modal-container .close-btn,.modal-header .close {
    background-color: #fff;
    outline: 10px solid #fff;
}

.modal-container .close-btn:focus {
    background-color: #b2f6ff
}
.modal-wrapper .modal-header.close{
    z-index: 999;
}
.modal-dialog-centered .modal-content .modal-header{
    border-bottom: unset;
}
.modal-dialog-centered .modal-content{
    border-radius: unset;
    padding: 30px;
}
.modal-body p{
    font-size: 12px;
}
@media (min-width:1024px) {
    .modal-container.modal-video {
        width: 50%
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

@media (min-width:1024px) {
    .video-player {
        -webkit-transform: none;
        transform: none;
        position: static
    }
}

.video-player .video-player-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%
}

.video-player .video-player-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.footer-img-container {
    padding: 0!important;
    min-height: 80px
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.privacy-modal {
    min-height: 500px;
    position: relative;
    overflow: hidden
}

.privacy-modal .inner-container {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 20px;
    bottom: 20px;
    overflow: auto;
    font-size: .7em
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.introducing-container {
    background-color: #fff;
    position: relative
}

.introducing-container .introducing-img-key {
    display: none
}

@media (min-width:1024px) {
    .introducing-container .introducing-img-key {
        display: block;
        position: absolute;
        top: -12px;
        left: 0
    }
}

.introducing-container .introducing-title {
    margin-bottom: 0
}

.introducing-container .introducing-turnkey-logo {
    max-height: 35px;
    margin-bottom: 50px
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.image-with-text-container .image-with-text-img-wrapper {
    display: inline-block;
    width: 100%
}

.image-with-text-container .image-with-text-img-wrapper.pointer {
    cursor: pointer
}

.image-with-text-container .image-with-text-img-wrapper .image-with-text-img {
    width: 100%
}

@media (min-width:1024px) {
    .image-with-text-container .image-with-text-img-wrapper {
        max-width: 45%
    }
}

.image-with-text-container .image-with-text-txt {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin: 20px 0;
    color: #585858;
    font-size: .75em
}

@media (min-width:1024px) {
    .image-with-text-container .image-with-text-txt {
        font-size: 1em;
        max-width: 45%;
        margin: 0 30px
    }
}

.image-with-text-container .image-with-text-txt p:first-child {
    margin-top: 0
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.tagWithNumber {
    line-height: 1.9;
    color: #fff;
    font-weight: 400
}

.tagWithNumber,
.tagWithNumber:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px
}

.tagWithNumber:before {
    content: "";
    background-color: #187B8B;
    height: 30px;
    transform: rotate(45deg);
    z-index: -1;
    display: block
}

@media (min-width:1024px) {
    .fullWidth {
        padding-left: 0!important;
        padding-right: 0!important
    }
}

.mobileFullWidth {
    padding-left: 0!important;
    padding-right: 0!important
}

.badge-diamond-container .badge-diamond-wrap {
    display: inline-block;
    width: 85px;
    height: 85px;
    background-color: #187B8B;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    margin-bottom: 10px
}

.badge-diamond-container .badge-diamond-wrap .badge-diamond-value {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-weight: 500;
    text-shadow: -1.5px 2px 1.5px #333;
    font-size: 1.8em;
    white-space: nowrap
}

.badge-diamond-container .badge-diamond-wrap .percentage {
    font-size: .83em;
    display: inline-block;
    vertical-align: top
}

.badge-diamond-container .badge-diamond-wrap .asterisk {
    font-size: .7em;
    display: inline-block;
    vertical-align: top
}

.badge-diamond-container .badge-diamond-text {
    color: #fff;
    font-weight: 500;
    max-width: 270px;
    margin: 30px auto;
    line-height: 1.6
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.rich-text-block,
.rich-text-block .common-text-line-height,
.rich-text-block .heading-2 {
    text-align: left
}

.rich-text-block .common-text-line-height {
    margin-bottom: 25px
}

.rich-text-block .heading-wrapper {
    position: relative;
    width: 100%
}

.rich-text-block .heading-wrapper .heading-2 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0
}

@media (min-width:768px) {
    .rich-text-block .heading-wrapper img {
        display: none
    }
    .rich-text-block .heading-wrapper .heading-2 {
        position: static;
        margin: 30px auto
    }
}

@media (min-width:768px) {
    .rich-text-block .heading-2 {
        text-align: center;
        max-width: 670px;
        margin: 0 auto 15px
    }
}

@media (min-width:768px) {
    .rich-text-block .content-container {
        max-width: 672px;
        margin-left: auto;
        margin-right: auto
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.tagWithNumber {
    line-height: 1.9;
    color: #fff;
    font-weight: 400
}

.tagWithNumber,
.tagWithNumber:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px
}

.tagWithNumber:before {
    content: "";
    background-color: #187B8B;
    height: 30px;
    transform: rotate(45deg);
    z-index: -1;
    display: block
}

@media (min-width:1024px) {
    .free-amazon-benefits,
    .fullWidth {
        padding-left: 0!important;
        padding-right: 0!important
    }
}

.mobileFullWidth {
    padding-left: 0!important;
    padding-right: 0!important
}

.free-amazon-benefits {
    padding-top: 0!important
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.overlap-image-with-text {
    margin-top: -40px;
    padding-top: 0!important;
    -webkit-transform: translateZ(10px);
    transform: translateZ(10px)
}

@media (min-width:1024px) {
    .overlap-image-with-text {
        margin-top: -80px
    }
}

.overlap-image-with-text .rich-text-container {
    text-align: left;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto
}

.overlap-image-with-text .rich-text-container ul {
    list-style-type: none
}

.overlap-image-with-text .rich-text-container ul li {
    position: relative;
    padding-bottom: 10px
}

@media (min-width:1024px) {
    .overlap-image-with-text .rich-text-container ul li {
        padding-bottom: 20px
    }
}

.overlap-image-with-text .rich-text-container ul li:before {
    content: "";
    position: absolute;
    background-color: #187B8B;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -20px;
    top: 5px
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.mosaic-images .row {
    overflow: hidden
}

.mosaic-images .row .mosaic-tile {
    vertical-align: top
}

.mosaic-images .row .mosaic-tile:first-of-type {
    padding-left: 0
}

.mosaic-images .row .mosaic-tile:last-of-type {
    padding-right: 0
}

.mosaic-images .first-row {
    max-height: 210px;
    margin-bottom: 10px
}

.mosaic-images .first-row .mosaic-tile {
    width: 50%;
    padding: 0 5px
}

@media (min-width:768px) {
    .mosaic-images .first-row {
        max-height: 480px
    }
}

.mosaic-images .second-row {
    max-height: 320px
}

.mosaic-images .second-row .mosaic-tile {
    width: calc(33% - 0.9px);
    padding: 0 5px
}

@media (min-width:1024px) {
    .mosaic-images {
        padding-top: 0;
        max-height: 315px;
        overflow: hidden;
        /* margin: 0px -30px; */
    }
    .mosaic-images .row {
        display: inline
    }
    .mosaic-images .row .mosaic-tile {
        width: 20.2%;
        padding: 0 .5%
    }
    .mosaic-images .first-row .mosaic-tile:first-of-type {
        padding-left: 0
    }
    .mosaic-images .first-row .mosaic-tile:last-of-type {
        padding-right: .5%
    }
    /* .mosaic-images .second-row .mosaic-tile:first-of-type {
        padding-left: .5%
    } */
    .mosaic-images .second-row .mosaic-tile:last-of-type {
        padding-right: 0
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.price-table-container {
    overflow: hidden
}

.price-table-container .price-description-container {
    margin-bottom: 20px
}

@media (min-width:1024px) {
    .price-table-container .price-description-container {
        border-left: 10px solid #f5f5f2;
        border-right: 10px solid #f5f5f2;
        vertical-align: top;
        width: 30%;
        display: table-cell;
        margin-bottom: 0
    }
}

.price-table-container .price-table-disclaimer {
    color: #4e4e4e
}

@media (min-width:1024px) {
    .price-table-container .price-table-disclaimer {
        display: none
    }
}

.price-table-container .price-table-title {
    margin: 0 auto 40px;
    font-size: 1.625em;
    font-weight: 500;
    color: #187B8B;
    width: 80%
}

@media (min-width:1024px) {
    .price-table-container .price-table-title {
        font-size: 2.5em
    }
}

.price-table-container .primary-button {
    margin-top: 20px
}

.price-table-container.homepage {
    margin-top: 0
}

.price-table-container.homepage:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #6c757d;
    display: block;
    margin: 0 auto 60px
}

.price-table-container.homepage .price-table-title {
    font-weight: 300;
    color: #000;
    max-width: 700px;
    font-size: 1.625em
}

@media (min-width:1024px) {
    .price-table-container.homepage .price-table-title {
        font-size: 2.25em
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.price-description-container {
    background-color: #fff
}

.price-description-container a {
    font-weight: 700;
    text-decoration: underline
}

.price-description-container .price-description-price {
    padding: 20px;
    text-align: left;
    position: relative
}

@media (min-width:1024px) {
    .price-description-container .price-description-price {
        padding: 30px 0 10px;
        text-align: center
    }
}

.price-description-container .price-description-price .price-description-icon-toggle {
    color: #187B8B;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .5s;
    transition: all .5s;
}

@media (min-width:1024px) {
    .price-description-container .price-description-price .price-description-icon-toggle {
        display: none
    }
}

.price-description-container .price-description-price .price-description-icon-toggle.active {
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg)
}

.price-description-container .price-description-price .price-description-icon-toggle:before {
    content: "";
    background-color: #187B8B;
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: 1px
}

.price-description-container .price-description-price .price-description-icon-toggle:after {
    content: "+";
    color: #fff;
    position: absolute;
    left: 5px;
    top: 0;
}

.price-description-container .price-description-more {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .5s;
    transition: max-height .5s;
    border-top: 1px solid #fff
}

@media (min-width:1024px) {
    .price-description-container .price-description-more {
        max-height: 100%;
        border-top: 1px solid #6c757d
    }
}

.price-description-container .price-description-more.active {
    border-top: 1px solid #6c757d;
    max-height: 1000px
}

.price-description-container .price-description-price-title {
    color: #6c757d;
    margin: 0 0 10px;
    /* display: none */
}

@media (min-width:1024px) {
    .price-description-container .price-description-price-title {
        display: block
    }
}

.price-description-container .price-description-price-amount {
    color: #187B8B;
    font-weight: 500;
    font-size: 1.6em;
    margin: 0
}

.price-description-container .price-description-value {
    border-bottom: 1px solid #6c757d;
    padding: 20px 0
}

.price-description-container .price-description-value-title {
    font-weight: 500;
    display: inline-block;
    margin-right: 30px
}

.price-description-container .price-description-value-amount {
    color: #187B8B;
    font-weight: 500;
    display: inline-block;
    font-size: 2em;
    vertical-align: middle
}

.price-description-container .price-description-info {
    text-align: left;
    margin: 30px 20px
}

.price-description-container .price-description-info-wrap p {
    /* margin-top: 10px; */
    color: #585858
}

.price-description-container .price-description-info-wrap ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px
}

.price-description-container .price-description-info-wrap li {
    color: #585858
}

.price-description-container .price-description-info-wrap span {
    color: #187B8B
}

.price-description-container .price-description-info-wrap a {
    text-decoration: underline
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.tagWithNumber {
    line-height: 1.9;
    color: #fff;
    font-weight: 400
}

.tagWithNumber,
.tagWithNumber:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px
}

.tagWithNumber:before {
    content: "";
    background-color: #187B8B;
    height: 30px;
    transform: rotate(45deg);
    z-index: -1;
    display: block
}

@media (min-width:1024px) {
    .fullWidth {
        padding-left: 0!important;
        padding-right: 0!important
    }
}

.mobileFullWidth,
.real-estate {
    padding-left: 0!important;
    padding-right: 0!important
}

.real-estate {
    padding-bottom: 0!important;
    margin-bottom: 10px!important
}

@media (min-width:768px) {
    .real-estate {
        color: #fff;
        background-size: cover;
        margin-left: 30px!important;
        margin-right: 30px!important;
        background-position: 50%
    }
}

@media (min-width:1024px) {
    .real-estate {
        margin-left: 0!important;
        margin-right: 0!important
    }
}

.real-estate .badges,
.real-estate .common-text-line-height,
.real-estate .heading-2 {
    padding-left: 30px;
    padding-right: 30px
}

.real-estate .rich-text-block {
    text-align: center
}

.real-estate .rich-text-block .heading-2 {
    color: #fff;
    text-align: center;
    line-height: 37px
}

.real-estate .rich-text-block .common-text-line-height {
    text-align: center
}

@media (min-width:768px) {
    .real-estate .rich-text-block .heading-2 {
        margin-bottom: 15px
    }
}

@media screen and (max-width:767px) {
    .real-estate .badges .badge-diamond-container {
        padding-left: 9px
    }
    .real-estate .badges .badge-diamond-text {
        color: #000;
        text-align: left
    }
}

@media screen and (max-width:768px) {
    .real-estate .badges .badge-diamond-wrap {
        height: 50px;
        width: 50px;
        margin: 0
    }
    .real-estate .badges .badge-diamond-wrap .badge-diamond-value {
        font-size: 1.1em
    }
    .real-estate .badges .badge-diamond-text {
        max-width: 58%;
        display: inline-block;
        padding-left: 20px
    }
}

@media (min-width:768px) {
    .real-estate .badges {
        text-align: center;
        padding-top: 30px
    }
    .real-estate .badges .badge-diamond-container {
        display: inline-block;
        max-width: 25%;
        vertical-align: top
    }
    .real-estate .badges .badge-diamond-text {
        max-width: 270px
    }
}

@media (min-width:1024px) {
    .real-estate .badges .badge-diamond-container {
        max-width: 230px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.sign-up {
    background-color: #187B8B;
    padding-bottom: 10px!important
}

@media (min-width:1024px) {
    .sign-up {
        min-height: 400px
    }
}

.sign-up .sign-up-logo {
    display: block;
    margin: auto
}

@media (min-width:768px) {
    .sign-up .sign-up-logo {
        max-width: 350px
    }
}

.sign-up .sign-up-form-row {
    margin: 20px auto
}

@media (min-width:1024px) {
    .sign-up .sign-up-form-row {
        width: 60%
    }
}

.sign-up .err-string {
    color: #fff;
    font-size: .8em;
    padding-bottom: 20px;
    margin: 0
}

@media (min-width:1024px) {
    .sign-up .sign-up-field {
        width: 40%;
        display: inline-block
    }
}

.sign-up .sign-up-field.sign-up-field-50 {
    width: calc(50% - 5px);
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0
}

@media (min-width:1024px) {
    .sign-up .sign-up-field.sign-up-field-50 {
        width: calc(50% - 20px);
        margin-right: 40px
    }
}

.sign-up .sign-up-field.sign-up-field-50:last-child {
    margin-right: 0;
}

.sign-up-field{
    text-align: left;
}

.invalid-msg{
    /* visibility: hidden; */
    color: #FFF;
    text-align: center;
    font-size: 14px;
}
.error .invalid-msg{
    /* visibility: visible; */
    font-size: 14px;
    color: #FFF;
    float: left;
}

.sign-up .input-submit {
    display: block;
    -webkit-appearance: none;
    margin: 10px auto 0
}

.sign-up .input-submit:not([disabled]) {
    border-color: #e0b67f;
    background-color: #e0b67f;
    color: #000
}

.sign-up .input-submit:disabled {
    background-color: #757575;
    border-color: #797979
}

.sign-up .sign-up-form-wrapper {
    overflow: hidden
}

@media (min-width:1024px) {
    .sign-up .sign-up-form-wrapper {
        margin: 30px 30px 0
    }
}

.sign-up .sign-up-form {
    margin: auto
}

.sign-up .sign-up-disclaimer {
    margin: 30px 0 0;
    color: #fff;
    max-width: 600px;
    margin: 30px auto 0;
    font-size: .5625em
}

@media (min-width:1024px) {
    .sign-up .sign-up-disclaimer {
        font-size: .75em
    }
}

.sign-up .sign-up-disclaimer a,
.sign-up .sign-up-disclaimer span {
    /* text-decoration: underline; */
    cursor: pointer;
    max-width: 600px;
    margin: 0 auto;
}

.sign-up .sign-up-disclaimer+.sign-up-disclaimer {
    margin-top: 10px
}

.sign-up .sign-up-disclaimer-info {
    color: #FFF;
    font-weight: 500;
    font-size: .75em;
    max-width: 260px;
    margin: 10px auto
}

@media (min-width:1024px) {
    .sign-up .sign-up-disclaimer-info {
        max-width: 80%;
        font-size: 1em
    }
}

.sign-up .sign-up-done {
    overflow: hidden;
    text-align: center
}

@media (min-width:1024px) {
    .sign-up .sign-up-done {
        width: 800px;
        padding: 30px 100px 0;
        text-align: left;
        position: static;
        -webkit-transform: initial;
        transform: none
    }
}

.sign-up .sign-up-done-img-wrap {
    margin-top: 40px
}

@media (min-width:1024px) {
    .sign-up .sign-up-done-img-wrap {
        float: left;
        margin-right: 80px;
        margin-top: 0
    }
}

.sign-up .sign-up-done-logo-img {
    display: block;
    margin: 0 auto 10px;
    width: 150px
}

@media (min-width:1024px) {
    .sign-up .sign-up-done-logo-img {
        width: 200px
    }
}

.sign-up .sign-up-done-img {
    width: 130px;
    display: inline-block
}

@media (min-width:1024px) {
    .sign-up .sign-up-done-img {
        height: 180px;
        width: 190px
    }
}

@media (min-width:1024px) {
    .sign-up .sign-up-done-info {
        float: left;
        width: 520px
    }
}

.sign-up .sign-up-done-info .close-button {
    background-color: #e0b67f;
    border-color: #e0b67f;
    color: #000;
    margin-top: 30px;
    margin-bottom: 10px
}

.sign-up .text-with-icons {
    margin: 0 auto 10px
}

@media (min-width:1024px) {
    .sign-up .text-with-icons {
        margin: 0 auto 44px
    }
}

.sign-up .close-btn {
    display: none
}

@media (min-width:1024px) {
    .sign-up .modal-container {
        width: 1000px
    }
}

@media only screen and (max-width:768px) {
    .sign-up.edge-browser .sign-up-field,
    .sign-up.ie-browser .sign-up-field {
        width: calc(100% - 40px);
        padding-bottom: 30px;
        margin-left: 0;
        margin-right: 30px!important
    }
    .sign-up.edge-browser .sign-up-field .icon-error,
    .sign-up.ie-browser .sign-up-field .icon-error {
        top: 10px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.input-field {
    width: 100%;
    margin: 0 0 20px;
    position: relative
}

@media (min-width:1024px) {
    .input-field {
        margin: 0 40px 10px 0
    }
    .input-field:last-child {
        margin-right: 0
    }
}

.input-field .input-field-label {
    display: block;
    margin-bottom: 5px;
    color: #FFF;
    font-weight: bold;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    font-size: .9em;
    text-align: left
}

.input-field .input-field-el {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid transparent;
    outline: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-weight: 500;
    font-size: .8em
}

@media only screen and (max-width:360px) {
    .input-field .input-field-el {
        font-size: .6em
    }
}

@media only screen and (max-width:375px) {
    .input-field .input-field-el {
        font-size: .75em;
        padding-left: 5px;
        padding-right: 5px
    }
}

@media (min-width:768px) {
    .input-field .input-field-el {
        font-size: .875em
    }
}

.input-field .input-field-el::-webkit-input-placeholder {
    color: #000;
    font-weight: 500
}

.input-field .input-field-el::-moz-placeholder {
    color: #000;
    font-weight: 500
}

.input-field .input-field-el::-ms-input-placeholder {
    color: #000;
    font-weight: 500
}

.input-field .input-field-el::placeholder {
    color: #000;
    font-weight: 500
}

.input-field .input-field-el:-ms-input-placeholder {
    color: #000;
    font-weight: 500
}

.input-field .input-field-el:invalid,
.input-field .input-field-el:valid {
    background-color: #fff
}

.input-field .input-field-el:hover {
    border-color: #000
}

.input-field .phone-number-label {
    position: absolute;
    color: #000;
    font-weight: 500;
    top: 13px;
    left: 10px
}

@media (min-width:768px) {
    .input-field .phone-number-label {
        font-size: .875em
    }
}

@media only screen and (max-width:450px) {
    .input-field .phone-number-label {
        font-size: .75em;
        left: 5px
    }
}

@media only screen and (max-width:360px) {
    .input-field .phone-number-label {
        font-size: .6em
    }
}

.input-field.focused .input-field-label {
    color: #fff
}

.input-field.focused .input-field-label.phone-number-label {
    color: #000
}

.input-field.focused .input-field-el {
    border: 2px solid #00717f;
    background-color: #fff
}

.input-field.error {
    position: relative
}

.input-field.error .input-field-label {
    color: #FFF
}

.input-field.error .input-field-label.phone-number-label {
    color: #000
}

.input-field.error .input-field-el {
    border-color: red;
    padding-right: 35px;
    background-color: #f2f2ef
}

.input-field.error .icon-error {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    display: inline-block
}

.input-field .placeholder {
    font-size: .75em;
    font-weight: 500;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 8px;
    pointer-events: none;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%
}

@media (min-width:768px) {
    .input-field .placeholder {
        font-size: .875em
    }
}

@media (min-width:1024px) {
    .input-field .placeholder {
        width: 100%;
        left: 12px
    }
}

.input-field.filled .placeholder {
    display: none
}

.input-field.input-phone input {
    -webkit-transition: none;
    transition: none
}

.input-field.input-phone.empty input {
    text-indent: 22px
}

@media only screen and (max-width:360px) {
    .input-field.input-phone.empty input {
        text-indent: 20px
    }
}

.input-field.input-phone.filled input {
    text-indent: 0
}

.ie-browser .focused .phone-number-label.input-field-label {
    color: transparent
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.select-field {
    margin: 0 0 20px
}

@media (min-width:1024px) {
    .select-field {
        margin: 0 10px 20px 0
    }
}

.select-field select::-ms-expand {
    display: none!important
}

.select-field .select-field-label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px
}

.select-field .select-field-wrapper {
    position: relative;
    display: block;
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid transparent
}

.select-field .select-field-wrapper:before {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    width: 40px;
    pointer-events: none
}

.select-field .select-field-wrapper:after {
    content: "";
    width: 0;
    height: 0;
    border-width: 0 6px 6px;
    border-style: solid;
    margin: 10px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transform-origin: right top;
    transform-origin: right top;
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 16px;
    z-index: 1;
    border-color: transparent transparent #6c757d;
    pointer-events: none
}

.select-field .select-field-wrapper:hover .select-field-el {
    border-color: #000
}

.select-field .select-field-wrapper:hover:after {
    color: #000
}

.select-field .select-field-el {
    padding: 11px 10px;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    outline: none
}

.select-field .select-field-el,
.select-field .select-field-el:invalid {
    background-color: #fff;
    color: #000;
    font-weight: 500
}

.select-field .select-field-el:valid {
    background-color: #fff
}

.select-field.focused .select-field-wrapper {
    border: 2px solid #00717f
}

.select-field.focused .select-field-wrapper:after {
    border-color: transparent transparent #187B8B
}

.select-field.focused .select-field-el,
.select-field.focused .select-field-el:focus {
    border-color: #187B8B;
    background-color: #fff
}

.select-field.error .select-field-el {
    border: 2px solid red
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.privacy-blade {
    font-size: .9em
}

.privacy-blade * {
    text-align: left
}

.privacy-blade .privacy-content {
    padding-top: 20px
}

@media (min-width:1024px) {
    .privacy-blade .privacy-content {
        padding-top: 0
    }
}

.privacy-blade .back-bar {
    height: 40px;
    box-sizing: border-box;
    background-color: #187B8B;
    color: #fff;
    border-bottom: 6px solid #e0b67f;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    font-weight: 700;
    line-height: 36px;
    padding-left: 30px
}

.privacy-blade .back-bar a {
    text-decoration: underline;
    padding-right: 40px
}

@media (min-width:1024px) {
    .privacy-blade .back-bar {
        padding-left: 80px
    }
}

@keyframes slideUp {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1
    }
}

@keyframes slideUpMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideUpCenterMobile {
    0% {
        top: 150%;
        opacity: 0
    }
    to {
        top: 50%;
        opacity: 1;
        transform: translateY(-50%)
    }
}

@keyframes slideDown {
    0% {
        top: 50%;
        opacity: 1
    }
    to {
        top: 150%;
        opacity: 0
    }
}

.iframe-modal .warning-content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    height: auto;
    overflow-y: auto;
    display: block;
    z-index: 1000
}

.iframe-modal .warning-content .title {
    text-align: center;
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
    box-sizing: border-box
}

@media (min-width:768px) {
    .iframe-modal .warning-content {
        left: 8%;
        right: 8%
    }
}

@media (min-width:1024px) {
    .iframe-modal .warning-content {
        left: 20%;
        right: 20%;
        top: 50%;
        height: 530px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

.iframe-modal .opacity-layer {
    background-color: #000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .8;
    display: block
}

.common-text-line-height ul li{
    position: relative;
    list-style: none;
}
.common-text-line-height ul li::before{
    /* list-style-image: url('../images/bulet.png'); */
    content: "";
    position: absolute;
    background-color: #187B8B;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -20px;
    top: 5px;
}
.accordion-item-content p{
    font-size: 15px;
}
/* .second-row.row,.first-row.row {
    margin: 0
} */
button.close{
    position: absolute;
    padding: 0;
    right: 34px;
    top: 26px;
    z-index: 999;
    background-color: unset;
    opacity: 0;
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover{
    opacity: 0;
}
p.accordion-item-trigger.heading-4{
    font-weight: 500;
}
a:focus,.image-with-text-img-wrapper:focus{
  outline: 2px solid black;
}
.wrapper.wrapper-footer{
    padding: 0;
}
/* .mosaic-images{
    margin: 0 -30px;
} */
.second-row.row {
    padding-left: 38px;
}
.modal-content{
    border: none;
}
.price-description-more{
    overflow: auto !important;
}

 h1, h2, h3, h4, h5, h6, h6 {
    font-weight: bold;
}

h2{
    font-size: 1.5em;
}

/* Css Added by Atinder*/


.price-table-container.homepage h4 {
    font-size: 16px;
}

.sign-up-form select:focus, button:focus, input:focus {
    border-color: #000;
    box-shadow: 0 0 3px 2px rgba(2, 2, 2, 0.5);
}
a.primary-button, a.button:hover {
    color: #ffffff;
    text-decoration: none;
}
.select-field .select-field-label {
    color: #fff;
} 
.accordion-item p {
    font-size: 13.6px;
}
body{
    color: #000;
}
.amazon-benefits .common-text-line-height {
    font-size: 1em;
    line-height: 1.5;}
.thank-you-signup p.badge-with-text-copy {
        color: #357b8b;
    }
.thank-you-signup  .three-column-grid {
        max-width: 80%;
    }
  
.thank-you-pricing h2.price-table-title {
        font-weight: 500 !important;
        color: #357b8b !important;
    }
.thank-you-pricing .price-table-container.homepage:before {
        background-color: transparent;
        display: block;
        margin: 0 auto 0px;
    }
.thank-you-pricing .price-table-container.homepage .price-table-title {
        max-width: 72%;
    } 
.thank-you-signup  .how-it-works-container.how-it-works-header {
        margin-bottom: 0px;
    }
@media (max-width:1120px) {

.how-it-works-header  .three-column-grid {
        max-width: 90%;
    }
.blade-container .image-with-text-txt p {
        line-height: 19.1px;
    }
}
@media (max-width: 1020px)
{
    .rich-text-block .heading-2 {
        text-align: center;
        max-width: 700px;
        margin: 0 auto 15px;
    }
    .second-row.row {
        padding-left: 0px;
    }
    .blade-container > div, .blade-container > header {
        padding: 32px 45px 80px;}
    .mosaic-images .second-row .mosaic-tile {
            width: calc(33% - -2.9px);}
}
@media (max-width:992px)
{
   
    .free-amazon-benefits {
        padding-bottom: 0 !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    .mosic-images .second-row.row {
        padding-left: 0px;
    }
    .second-row.row {
        padding-left: 0px;
    }
    .blade-container > div, .blade-container > header {
        padding: 32px 30px 80px;
    }
}
@media (max-width: 768px){
    .blade-container > div, .blade-container > header {
        padding: 32px 15px 80px;
    }
}

@media (max-width: 767px){
.how-it-works-container.how-it-works-header {
    margin-bottom: 80px;
}
.real-estate .badges .badge-diamond-text {
    max-width: 63%;
}
.amazon-benefits h2.heading-2 {
    text-align: center;
    padding-bottom: 10px;
}
.col-lg-12.amazon-role .rich-text-block .heading-2 {
    text-align: left;
    margin-left: 0;
}
}
@media screen and (max-width: 640px)
{
    .header {
        background-image: url(../images/786a958c03cee290753b1d7d4b00d1b5.jpg) !important;}
.real-estate .badges .badge-diamond-text {
    max-width: 75%;
}
.thank-you-pricing .price-table-container.homepage .price-table-title {
    max-width: 100%;
}
}
@media screen and (max-width:320px)
{

    .sign-up .sign-up-form-row {
        margin: 12px auto;
        font-size: 11px;
    }
}

/* Atinder Css End here*/
.free-amazon-benefits{
    padding-bottom: 0 !important;
}
.price-description-info-wrap ul li p{
    margin: 0;
}
.price-description-info-wrap b{
    color: #167b8b;
    font-weight: 100;
}
.header .how-it-works-container.how-it-works-header .badge-with-text-copy {
    color: #ffffff!important;
}
.sign-up .how-it-works-container.how-it-works-header .badge-with-text-copy {
    color: #000000!important;
}

.modal-close-btn:focus{
    border: 2px solid #000;
}
.carousel-control-prev:focus span,
.carousel-control-next:focus span{
    border: 2px solid #000
}
.terms:hover{
    color: #ffc107;
}
.button.sc-AxiKw.jChMAH.padding.terms{
    display: block;
    margin: 0px auto;
}
.sign-up-disclaimer-wrapper{
    max-width: 600px;
    margin: 30px auto 0;
}
.sign-up-disclaimer-wrapper .sign-up-disclaimer p{
    display: inline;
}
button.sc-AxirZ.hAoUa-D.padding.terms{
    display: block;
    max-width: 600px;
    margin: auto;
    padding-left: 0;
}
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .mosaic-images .first-row{
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
    }
    .mosaic-images .first-row .mosaic-tile{
        width: 50%;
    }
    .mosaic-images .second-row .mosaic-tile {
        width: calc(33% - -2.1px);
    }
    .mosaic-images .second-row{
        margin: 0;
    }
    .second-row.row {
        padding-left: 0;
    }
    .sign-up-disclaimer-wrapper{
        max-width: 614px;
        margin: 30px auto 0;
    }
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .mosaic-images .first-row{
        margin-bottom: 10px 0;
    }
    .three-column-grid{
        max-width: 91%;
    }
  }
  @media only screen and (max-width: 600px){
    .real-estate{
        background-image: unset !important;
    }
    .mosaic-images .second-row .mosaic-tile {
        width: 33.3%;
        height: 150px;
  }
  .second-row.row {
    padding-left: 0;
    margin: 0;
    }
    .first-row.row{
        margin: 10px 0;
    }
    #header-signup-cta{
        margin-top: 79px;
    }
}
@media only screen and (max-width: 768px) and (orientation:landscape){
    .second-row.row{
        padding-left:0;
        margin: 0;
    }
    .first-row.row{
        margin: 10px 0;
    }
    .mosaic-images .second-row .mosaic-tile{
        width: 33.3%;
    }
    #header-signup-cta{
        margin-top: 79px;
    }
}